import {useQuery} from 'react-query';

const BASE_URL = `${process.env.REVIEW_SUMMARIZATION_URL}`;

const fetchProductReviewSummarization = async (styleId) => {
  const fetchUrl = `${BASE_URL}/summary?styleId=${styleId}`;
  const response = await fetch(fetchUrl);
  if (!response.ok) {
    throw new Error('Failed to fetch trustpilot product reviews', {
      response,
      styleId
    });
  }
  return response.json();
};

const useProductReviewSummarization = (styleId, enabled) => {
  return useQuery({
    enabled,
    queryFn: () => fetchProductReviewSummarization(styleId),
    queryKey: ['review_summarization', styleId]
  });
};

export default useProductReviewSummarization;
